import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@contexts/AuthContext';
import { AppProvider } from '@contexts/AppContext';
import { init as initFullStory } from '@fullstory/browser';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initFullStory({ orgId: 'o-1CZ4ZR-na1', recordCrossDomainIFrames: true, devMode: process.env.REACT_APP_ENV !== 'prod' })

root.render(
  <React.StrictMode>
    <AppProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
