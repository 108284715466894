import PageHeader from '@components/PageHeader';
import {
  Box,
  Grid,
  Typography,
  Chip,
  TextField,
  MenuItem,
  Pagination,
  PaginationItem,
  styled,
  SwipeableDrawer,
  drawerClasses,
  Stack,
  TablePagination,
  DialogContent,
  Button,
  Autocomplete,
  Popper,
} from '@mui/material';
import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowsProp,
  GridSortModel,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import { WORK_TYPE } from '@utils/models';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import WorkOrderCard from '@components/cards/WorkOrderCard';
import PrimaryButton from '@components/buttons/PrimaryButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ActionCard from '@components/cards/ActionCard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { DrawerType, getColorByText, getInitials, priorityOptions, statusOptions } from '@utils/helpers';
import { ReactComponent as PersonAddIcon } from '../assets/icons/person-add.svg';
import GridEditInput from '@components/workOrder/GridEditInput';
import WorkOrderGrid from '@components/workOrder/WorkOrderGrid';
import PropertySelector from '@components/inputs/PropertySelect';
import { useIsMobile } from '@hooks/useIsMobile';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterAltRounded';
import SortIcon from '@mui/icons-material/SwapVertRounded';
import WorkOrderDetail from '@components/workOrder/Detail';
import { useAuth } from '@contexts/AuthContext';
import CreateWO from '@components/drawers/create-workorder';
import EditPriorityModal from '@components/workOrder/edit-priority-mobile';
import EditTechModal from '@components/workOrder/edit-tech-mobile';
import palette from '@utils/palette';
import { useNavigate } from 'react-router-dom';
import { MobileSelect } from './DashboardPage';
import SelectDrawer from '@components/drawers/SelectDrawer';
import { getRequest, putRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { Dialog } from '@ui/dialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DataRowCard from '@components/cards/DataRowCard';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function createData(
  number: string,
  description: string,
  category: string[],
  unit: string,
  appointment: string,
  priority: string,
  assignedTo: string,
  reportedDate: string,
  status: string,
  completedDate: string,
  scheduledDate: string,
  other: any,
) {
  return {
    id: number,
    number,
    description,
    category,
    unit,
    appointment: (appointment == 'Call' && other.scheduledDate ? DateTime.fromISO(other.scheduledDate.toString()).toFormat('MM/dd/yyyy') : appointment == 'Call'  ? 'Appointment Required': appointment == 'Permission Given' ? 'Permission to Enter' : ''),
    priority,
    assignedTo,
    reportedDate,
    status,
    completedDate,
    scheduledDate,
    other
  };
}

const SwipeableDrawerComp = styled(SwipeableDrawer)(() => {
  return {
    [`&.${drawerClasses.root} .${drawerClasses.paper}`]: {
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24
    }
  };
});

export default function OpenWorkOrderPage() {
  const [openCreateWO, setOpenCreateWO] = useState(false);
  const isMobile = useIsMobile();
  const [seeAll, setSeeAll] = useState(false);
  const [selectedWo, setSelectedWo] = useState<any>(null);
  const { user, updateTechList, isPmRole } = useAuth();

  const navigate = useNavigate();

  // Search
  const [editPriorityModal, setEditPriorityModal] = useState(false);
  const [editTechModal, setEditTechModal] = useState(false);

  const us: any[] = [];
  user!!.techUsers.forEach((u) => {
    us.push({ ...u, name: u.firstName + ' ' + u.lastName.trim() });
  })
  const techOptions = us;

  const [unitOptions, setUnitOptions] = useState([]);

  const serviceTypeOptions = user?.categories;

  // Grid
  const [sortModel, setSortModel] = useState<GridSortModel | any>([
    { field: 'reportedDate', sort: 'desc', label: 'Date Reported', sortLabel: 'Descending' }
  ]);
  const [sortModelCopy, setSortModelCopy] = useState<GridSortModel | any>([
    { field: 'reportedDate', sort: 'desc', label: 'Date Reported', sortLabel: 'Descending' }
  ]);

  const [sortModelLocal, setSortModelLocal] = useState<GridSortModel | any>([
    { field: 'reportedDate', sort: 'desc', label: 'Date Reported', sortLabel: 'Descending' }
  ]);

  const [filterModel, setFilterModel] = useState<any>({
    status: statusOptions.filter((s) => ['Closed', 'Completed', 'Cancelled'].indexOf(s.name) < 0)
  });
  const [filterModelCopy, setFilterModelCopy] = useState<any>({
    status: statusOptions.filter((s) => ['Closed', 'Completed', 'Cancelled'].indexOf(s.name) < 0)
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0
  });
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [total, setTotal] = useState(0);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const onCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    switch (params.field) {
      case 'category':
      case 'priority':
      case 'assignedTo':
      case 'status':
      case '__check__':
        isPmRole() ? handleCellClick(params, event) : setSelectedWo(params.row.other);
        break;
      default:
        setSelectedWo(params.row.other);
        break;
    }
  };
  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }
      // Ignore portal
      if (!event.currentTarget.contains(event.target as Element)) {
        return;
      }
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        };
      });
    },
    []
  );

  const updateWorkOrders = async(e: any) => {
    const wos = e.rows.filter((r: any) => e.selectedRows.indexOf(r.number) >= 0);
    switch(e.action) {
      case 'assign':
        wos.forEach(async(wo: any) => {
          await putRequest(`work-orders/${wo.other.id}`, {assignedTo: e.value.name || e.value.firstName + ' ' + e.value.lastName, assignedToPersonId: e.value.thirdPartyId}, {
            headers: {
              Authorization: `Bearer ${user!!.token}`
            }
          });
        });
        break;
      case 'priority':
        wos.forEach(async(wo: any) => {
          await putRequest(`work-orders/${wo.other.id}`, {priority: e.value}, {
            headers: {
              Authorization: `Bearer ${user!!.token}`
            }
          });
        });
        break;
      case 'status':
        wos.forEach(async(wo: any) => {
          await putRequest(`work-orders/${wo.other.id}`, {status: e.value}, {
            headers: {
              Authorization: `Bearer ${user!!.token}`
            }
          });
        });
        break;
    }
    toast.success(`Update ${wos.length} work order(s) successfully`);
    setTimeout(() => {
      getGridData();
    }, 1000);
  }

  function getFilter() {
    let url = `work-orders?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}&propertyId=${user!!.selectedProperty.id}`;
    if(sortModel && sortModel.length) {
      url += `&sort=${sortModel[0].field}&order=${sortModel[0].sort}`;
    }
    if(searchInput) {
      url += `&q=${searchInput}`;
    }
    if(!isPmRole()) {
      url += `&assignedToId=${user?.id}`
    }
    if(filterModel.unit) {
      filterModel.unit.forEach((u: any) => {
        url += `&unit=${u.name}`;
      });
    }
    if(filterModel.status) {
      filterModel.status.forEach((status: any) => {
        url += `&status=${['Submitted', 'Not Started', 'Unassigned'].indexOf(status.name) >= 0 ? 'Submitted' : status.name}`
      });
    }
    if(filterModel.priority) {
      filterModel.priority.forEach((priority: any) => {
        url += `&priority=${priority.name}`;
      });
    }
    if(filterModel.category) {
      filterModel.category.forEach((category: any) => {
        url += `&category=${category.name}`;
      });
    }
    if(filterModel.technician) {
      filterModel.technician.forEach((technician: any) => {
        url += `&assignedToId=${technician.id}`;
      });
    }
    return url;
  }

  async function getGridData() {
    setTotal(0);
    let url = getFilter();
    try {
      const resp = await getRequest(url, {
        headers: {
          Authorization: `Bearer ${user!!.token}`
        }
      });
      setTotal(resp.data.count);
      const responseData = resp.data.rows;
      const rowData = responseData.map((row: any) => {
        const {
          number,
          description,
          category,
          unit,
          appointment,
          priority,
          assignedTo,
          reportedDate,
          status,
          completedDate,
          scheduledDate
        } = row;

        const dateReceivedFormatted = reportedDate
          ? DateTime.fromISO(reportedDate.toString()).toFormat('MM/dd/yyyy')
          : '';
        const completedDateFormatted = completedDate
          ? DateTime.fromISO(completedDate.toString()).toFormat('MM/dd/yyyy')
          : '';
        const scheduledDateFormatted = scheduledDate
          ? DateTime.fromISO(scheduledDate.toString()).toFormat('MM/dd/yyyy')
          : '';
        return createData(
          number,
          description,
          category,
          unit,
          appointment,
          priority,
          assignedTo,
          dateReceivedFormatted,
          status,
          completedDateFormatted,
          scheduledDateFormatted,
          row
        );
      });

      setRows(rowData);
    } catch (err) {
      console.error(err);
    }
  }

  function getTypeChip(type: string) {
    switch (type) {
      case WORK_TYPE.PLUMING:
        return (
          <Chip label={type} sx={{ bgcolor: '#CEE6FF', color: '#3478B7' }} />
        );
      case WORK_TYPE.HVAC:
        return (
          <Chip label={type} sx={{ bgcolor: '#D6F9E5', color: '#4EAA76' }} />
        );
      case WORK_TYPE.ELECTRIC:
        return (
          <Chip label={type} sx={{ bgcolor: '#FFF3C4', color: '#CB6E17' }} />
        );
      case WORK_TYPE.INTERIOR:
        return (
          <Chip label={type} sx={{ bgcolor: '#FFD0B5', color: '#F9703E' }} />
        );
      case WORK_TYPE.APPLIANCE:
        return (
          <Chip label={type} sx={{ bgcolor: '#FBCEFF', color: '#B734A2' }} />
        );
      case WORK_TYPE.HAZARD:
        return (
          <Chip label={type} sx={{ bgcolor: '#FFBDBD', color: '#AB091E' }} />
        );
      case WORK_TYPE.PREVENT:
        return (
          <Chip label={type} sx={{ bgcolor: '#EDF1F3', color: '#2B363B' }} />
        );
      default:
        return <Chip label={type} />;
    }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hideable: true },
    {
      field: 'description',
      headerName: 'Work Order',
      flex: 1,
      minWidth: 280,
      sortable: false,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" fontWeight={500} color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '1rem'
          }}
          title={`#${params.row.number} | ${params.value}`}
        >
          <span>#{params.row.number}</span>
          <span> | </span>
          <span>{params.value}</span>
        </span>
      )
    },
    {
      field: 'unit',
      headerName: 'Unit/Apt',
      flex: 1,
      minWidth: 120,
      sortable: true,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px'
          }}
          title={params.value}
        >
          {params.value}
        </span>
      )
    },
    {
      field: 'category',
      headerName: 'Type',
      flex: 1,
      minWidth: 120,
      sortable: false,
      editable: isPmRole(),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px'
          }}
          title={params.value}
        >
          {getTypeChip(params.value)}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <GridEditInput editParams={params} options={serviceTypeOptions!!} extraWidth={100} onClose={getGridData} />
        );
      }
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      minWidth: 120,
      sortable: true,
      editable: isPmRole(),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
            background: priorityOptions.find((s) => s.name === params.value)?.background,
            color: priorityOptions.find((s) => s.name === params.value)?.color,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid #fff'
          }}
          title={params.value}
        >
          {params.value}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <GridEditInput
            editParams={params}
            options={priorityOptions}
            extraWidth={50}
            onClose={getGridData}
          />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      sortable: true,
      editable: isPmRole(),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px',
            background: statusOptions.find((s) => s.name === params.value || (s.name === 'Unassigned' && params.value === 'Submitted'))?.background,
            color: statusOptions.find((s) => s.name === params.value || (s.name === 'Unassigned' && params.value === 'Submitted'))?.color,
            fontWeight: 500,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '0.5px solid #fff'
          }}
          title={params.value}
        >
          {params.value}
        </Box>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <GridEditInput
            editParams={params}
            options={statusOptions}
            extraWidth={50}
            onClose={getGridData}
          />
        );
      }
    },
    {
      field: 'assignedTo',
      headerName: 'Technician',
      flex: 1,
      minWidth: 200,
      sortable: false,
      editable: isPmRole(),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px'
          }}
          title={params.value}
        >
          {params.value && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  lineHeight: '24px',
                  border: '1px solid #00000014',
                  borderRadius: '50%',
                  textAlign: 'center',
                  background: getColorByText(params.value),
                  color: '#fff',
                  fontSize: '12px'
                }}
              >
                {getInitials(params.value)}
              </Box>
              <Box pl={1}>{params.value}</Box>
            </Box>
          )}
          {!params.value && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <PersonAddIcon />
              <Typography variant="body2" fontWeight={500} pl={1}>
                Add Technician
              </Typography>
            </Box>
          )}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <GridEditInput editParams={params} options={techOptions} onClose={getGridData} />;
      }
    },
    {
      field: 'reportedDate',
      headerName: 'Date Received',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      minWidth: 120,
      sortable: true,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params?.value
          ? DateTime.fromFormat(params?.value, 'MM/dd/yyyy')
            .toLocal()
            .toFormat('dd, MMM, yyyy')
          : '';
      },
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px'
          }}
          title={params.value}
        >
          {params.value}
        </span>
      )
    },
    {
      field: 'scheduledDate',
      headerName: 'Date Scheduled',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      minWidth: 120,
      sortable: true,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params?.value
          ? DateTime.fromFormat(params?.value, 'MM/dd/yyyy')
            .toLocal()
            .toFormat('dd, MMM, yyyy')
          : '';
      },
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px'
          }}
          title={params.value}
        >
          {params.value}
        </span>
      )
    },
    {
      field: 'completedDate',
      headerName: 'Date Completed',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      minWidth: 120,
      sortable: true,
      editable: false,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="body1" color={'#5A6A72'}>
          {params.colDef.headerName}
        </Typography>
      ),
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return params?.value
          ? DateTime.fromFormat(params?.value, 'MM/dd/yyyy')
            .toLocal()
            .toFormat('dd, MMM, yyyy')
          : '';
      },
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '16px'
          }}
          title={params.value}
        >
          {params.value}
        </span>
      )
    },
  ];
  
  useEffect(() => {
    getRequest(`units/${user?.selectedProperty.id}`, {
      headers: {
          Authorization: `Bearer ${user!!.token}`
      }
    }).then((res) => {
      const units = res.data;
      units.forEach((u: any) => u.name = u.unitNumber);
      setUnitOptions(units);
    });
  }, []);

  useEffect(() => {
    getGridData();
  }, [paginationModel, user, sortModel]);

  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });

  const getActiveJobs = (tu: any) => {
    return (user?.aJobs[tu.name] ?? 0) + ' '
  }

  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [filterExpanded, setFilterExpanded] = useState(false);
  const [sortExpanded, setSortExpanded] = useState(false);

  const handleSearchKeyPress = (e: any) => {
    if(e.key === 'Enter') {
      getGridData();
      setSearchExpanded(false);
    }
  }
  const [activeAccordian, setActiveAccordian] = useState('');
  const accRef = useRef(null);
  const toggleAccordian = (val: string) => {
    if(activeAccordian === val) {
      setActiveAccordian('');
    } else {
      setActiveAccordian(val);
    }
  }
  
  return (
    <>
      <Box>
        <PageHeader
          title={<FormattedMessage id='Work Orders' />}
          selection={(user?.properties?.length || 0) > 1 && <PropertySelector />}
          action={
            <PrimaryButton
              onClick={() => {
                setOpenCreateWO(true);
              }}
              btnText="New Work Order"
              isSmall
              startIcon={<AddCircleOutlineIcon />}
              style={{ p: '8px 8px' }}
            />
          }
        />
        {isMobile ? (
          <Stack direction="row" spacing={2} sx={{ backgroundColor: palette.white, mx: -2, px: 2, mt: -4, pt: 4, mb: -2, pb: 2 }}>
            <Box onClick={() => {
              setDrawer({
                open: true,
                type: DrawerType.PropertyFilter
              })
            }}>
              { (user?.properties?.length || 0) > 1 ? <PropertySelector
                sx={{
                  pointerEvents: 'none'
                }} input={<MobileSelect />}
                renderValue={(displayValue) => (value: any) => displayValue}
              /> : '' }
            </Box>
          </Stack>
        ) : null}
        <Grid container sx={{...(isMobile ? { mt: 3 } : {})}}>
          {/* Desktop */}
          {(
            <>
              {!isMobile && isPmRole() &&<Grid item xs={12} pb={2}>
                {!seeAll && <Grid container spacing={2}>
                  {user?.techUsers.slice(0, 3).map((tu, idx) => (
                    <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
                      <WorkOrderCard
                        name={tu.name}
                        description={<>{getActiveJobs(tu)} <FormattedMessage id='Open Work Orders' /></>}
                        onClick={() => {navigate(`/profile/${tu.id}`)}}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ActionCard
                      title={<FormattedMessage id="See All" />}
                      onClick={() => {
                        setSeeAll(true);
                      }}
                    />
                  </Grid>
                </Grid>}

                {seeAll && (
                  <Grid container spacing={2}>
                    {user?.techUsers.map((tu, idx) => (
                      <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
                        <WorkOrderCard
                          name={tu.name}
                          description={<>{getActiveJobs(tu)} <FormattedMessage id='Open Work Orders' /></>}
                          onClick={() => {navigate(`/profile/${tu.id}`)}}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <ActionCard
                        title={'See Less'}
                        onClick={() => {
                          setSeeAll(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>}
              {isMobile && <Typography sx={{padding: 1}}>Showing {total} results</Typography>}
              <Box pb={2} sx={{display: 'flex', flexDirection: 'row', gap: 4}}>
                {!isMobile && <Typography sx={{padding: 1}}>Showing {total} results</Typography>}
                <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, padding: 1, cursor: 'pointer', '&:hover': searchExpanded ? {} : {background: '#3034361A'}, borderRadius: 2 }} onClick={() => {setSearchExpanded(true)}}>
                  {searchExpanded ? <>
                    <TextField autoFocus={true} value={searchInput} onBlur={() => setSearchExpanded(false)} onChange={(e) => setSearchInput(e.target.value)} onKeyDown={handleSearchKeyPress} variant='outlined' size='small' placeholder='Search' InputProps={{startAdornment: <SearchIcon/> }}></TextField>
                  </> : 
                  <><SearchIcon /><Typography>Search</Typography></>}
                </Box>
                <Box sx={{display: 'flex'}}>
                  <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, padding: 1, cursor: 'pointer', '&:hover': filterExpanded ? {} : {background: '#3034361A'}, borderRadius: 2}} onClick={() => setFilterExpanded(true)}>
                    <FilterIcon />
                    {Object.keys(filterModel).length ? <Box sx={{marginTop: '0px', marginLeft: '-18px', width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                    {!searchExpanded && <Typography>Filter</Typography>}
                  </Box>
                  {isMobile && <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, padding: 1, cursor: 'pointer', '&:hover': sortExpanded ? {} : {background: '#3034361A'}, borderRadius: 2}} onClick={() => setSortExpanded(true)}>
                    <SortIcon />
                    {!searchExpanded && <Typography>Sort</Typography>}
                  </Box>}
                </Box>
              </Box>
              {!isMobile && <Grid item xs={12}>
                <Box
                  sx={{
                    py: 2,
                    background: '#FFFFFF',
                    borderRadius: '16px',
                    boxShadow: '0px 6px 24px 0px #3034361A'
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} px={1}>
                      <WorkOrderGrid
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              id: false,
                            }
                          }
                        }}
                        columns={columns}
                        rows={rows}
                        total={total}
                        setPaginationModel={setPaginationModel}
                        paginationModel={paginationModel}
                        setSortModel={setSortModel}
                        sortModel={sortModel}
                        setCellModesModel={setCellModesModel}
                        cellModesModel={cellModesModel}
                        onCellClick={onCellClick}
                        checkboxSelection={isPmRole()}
                        enableRowSelectAction={true}
                        onAction={(e: any)=>{
                          updateWorkOrders(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>}
              {isMobile && <Grid container spacing={2} sx={{paddingBottom: 4}}>
                {rows.map((row, idx) => (
                  <Grid key={idx} item xs={12} >
                    {isPmRole() ? 
                      <DataRowCard
                        data={row}
                        onClick={(type) => {
                          if (type === 'details') {
                            setSelectedWo(row.other);
                          } else if (type === 'technician') {
                            setSelectedWo(row.other);
                            setEditTechModal(true);
                          } else if (type === 'priority') {
                            setSelectedWo(row.other);
                            setEditPriorityModal(true);
                          }
                        }}
                      /> : 
                      <DataRowCard data={row} tech={true}
                        onClick={(type) => {
                          setSelectedWo(row.other);
                        }
                      } />
                    }
                  </Grid>
                ))}
              </Grid>}
              {/* Pagination */}
              {!isMobile && <Grid item xs={12} pt={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 5
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography pr={2} pt={1}>
                      <FormattedMessage id="Rows Per Page" />
                    </Typography>
                    <TextField
                      select
                      label=""
                      defaultValue="10"
                      size="small"
                      onChange={(e) => {
                        setPaginationModel({
                          page: 0,
                          pageSize: parseInt(e.target.value, 10)
                        });
                      }}
                      sx={{ bgcolor: '#E8EBEE' }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </TextField>
                  </Box>
                  <Pagination
                    page={paginationModel.page + 1}
                    count={Math.ceil(total / paginationModel.pageSize)}
                    shape="rounded"
                    onChange={(e, page) =>
                      setPaginationModel({
                        page: page - 1,
                        pageSize: paginationModel.pageSize
                      })
                    }
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: NavigateBeforeIcon,
                          next: NavigateNextIcon
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              </Grid>}
              {isMobile && <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 5
                  }}
                >
                  <TablePagination
                    component={'div'}
                    count={total}
                    page={paginationModel.page}
                    rowsPerPage={paginationModel.pageSize}
                    labelRowsPerPage={'Rows'}
                    onPageChange={(e, page) =>
                      setPaginationModel({
                        page: page,
                        pageSize: paginationModel.pageSize
                      })
                    }
                    onRowsPerPageChange={(e) =>
                      setPaginationModel({
                        page: 0,
                        pageSize: parseInt(e.target.value, 10)
                      })
                    }
                  />
                </Box>
              </Grid>}
            </>
          )}
        </Grid>
      </Box>
      {openCreateWO && <CreateWO onClose={() => setOpenCreateWO(false)}/>}
      {selectedWo && !editPriorityModal && !editTechModal && <WorkOrderDetail wo={selectedWo} onClose={() => {setSelectedWo(null); getGridData();}} />}
      {selectedWo && editPriorityModal && <EditPriorityModal wo={selectedWo} options={priorityOptions} onClose={() => {setSelectedWo(null); setEditPriorityModal(false); getGridData();}} />}
      {selectedWo && editTechModal && <EditTechModal wo={selectedWo} options={techOptions} onClose={() => {setSelectedWo(null); setEditTechModal(false); getGridData();}} />}
      {isMobile ? <>
          <SelectDrawer
            open={drawer.open && drawer.type === DrawerType.PropertyFilter}
            onClose={() => {
              setDrawer({
                open: false,
                type: null
              })
            }}
            options={(user || [] as any).properties.map((p: any) => ({ label: p.name, value: p.id }))}
            onChange={(value) => {
              const sp = (user?.properties || []).find((p) => p.id == (value));
              updateTechList(sp);
              setDrawer({
                open: false,
                type: null
              })
            }}
            name="property-filter"
            selected={user!!.selectedProperty.id}
            submitBtnLabel='View Property'
            heading="Select Property to View"
          />
        </> : ''}
      {filterExpanded ? isMobile ? <SwipeableDrawerComp 
        anchor={'bottom'} open={filterExpanded} onOpen={() => {}} onClose={() => {
          setFilterExpanded(false);
          setActiveAccordian('');
          setFilterModel(filterModelCopy);
        }}>
        <Box sx={{padding: 5}}>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography sx={{fontWeight: 500, color: '#212121'}}>Filter:</Typography>
            <Typography sx={{color: '#212121', cursor: 'pointer'}} onClick={() => {
              setFilterModel({});
            }}>Clear</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2}}>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} ref={accRef}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('unit')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Unit</Typography>
                  {activeAccordian !== 'unit' && filterModel.unit?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'unit' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  disableCloseOnSelect
                  multiple
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.unit}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, unit: val});
                  }}
                  options={unitOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('status')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Status</Typography>
                  {activeAccordian !== 'status' && filterModel.status?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'status' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.status}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, status: val});
                  }}
                  options={statusOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('category')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Category</Typography>
                  {activeAccordian !== 'category' && filterModel.category?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'category' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.category}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, category: val});
                  }}
                  options={user?.categories ?? []}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('priority')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Priority</Typography>
                  {activeAccordian !== 'priority' && filterModel.priority?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'priority' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'} 
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.priority}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, priority: val});
                  }}
                  options={priorityOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            {isPmRole() && <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('technician')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Technician</Typography>
                  {activeAccordian !== 'technician' && filterModel.technician?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'technician' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.technician}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, technician: val});
                  }}
                  options={techOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>}

            {/* <Box sx={{display: 'flex', justifyContent: 'space-between', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} onClick={() => toggleAccordian('dateReported')}>
              <Typography>Date Created</Typography>
              <ExpandMoreIcon />
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} onClick={() => toggleAccordian('dateCompleted')}>
              <Typography>Date Completed</Typography>
              <ExpandMoreIcon />
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} onClick={() => toggleAccordian('dateScheduled')}>
              <Typography>Date Scheduled</Typography>
              <ExpandMoreIcon />
            </Box> */}
            <Button variant='contained' onClick={() => {
              getGridData();
              setActiveAccordian('');
              setFilterExpanded(false);
              setFilterModelCopy(filterModel);
            }}>
              <Typography sx={{color: '#fff'}}>Apply Filters</Typography>
            </Button>
          </Box>
        </Box>
      </SwipeableDrawerComp>
      :
      <Dialog open={filterExpanded} onClose={(ev, reason) => {
          if (reason && reason === "backdropClick") {
            setFilterExpanded(false);
            setActiveAccordian('');
            setFilterModel(filterModelCopy);
          }
        }}>
        <DialogContent
          sx={{
            "& .MuiDialogContent-root": {
              padding: 5,
            },
          }}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography sx={{fontWeight: 500, color: '#212121'}}>Filter:</Typography>
            <Typography sx={{color: '#212121', cursor: 'pointer'}} onClick={() => {
              setFilterModel({});
            }}>Clear</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2}}>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('unit')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Unit</Typography>
                  {activeAccordian !== 'unit' && filterModel.unit?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'unit' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.unit}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, unit: val});
                  }}
                  options={unitOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('status')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Status</Typography>
                  {activeAccordian !== 'status' && filterModel.status?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'status' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.status}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, status: val});
                  }}
                  options={statusOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('category')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Category</Typography>
                  {activeAccordian !== 'category' && filterModel.category?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'category' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.category}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, category: val});
                  }}
                  options={user?.categories ?? []}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('priority')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Priority</Typography>
                  {activeAccordian !== 'priority' && filterModel.priority?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'priority' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'} 
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.priority}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, priority: val});
                  }}
                  options={priorityOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>
            {isPmRole() && <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}} onClick={() => toggleAccordian('technician')}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Technician</Typography>
                  {activeAccordian !== 'technician' && filterModel.technician?.length ? <Box sx={{ width: '10px', height: '10px', background: '#57BD83', borderRadius: '10px'}}></Box> : <></>}
                </Box>
                <ExpandMoreIcon />
              </Box>
              {activeAccordian === 'technician' && <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  multiple
                  disableCloseOnSelect
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={filterModel?.technician}
                  onChange={(ev, val) => {
                    setFilterModel({...filterModel, technician: val});
                  }}
                  options={techOptions}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.name}/>
              </Box>}
            </Box>}

            {/* <Box sx={{display: 'flex', justifyContent: 'space-between', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} onClick={() => toggleAccordian('dateReported')}>
              <Typography>Date Created</Typography>
              <ExpandMoreIcon />
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} onClick={() => toggleAccordian('dateCompleted')}>
              <Typography>Date Completed</Typography>
              <ExpandMoreIcon />
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} onClick={() => toggleAccordian('dateScheduled')}>
              <Typography>Date Scheduled</Typography>
              <ExpandMoreIcon />
            </Box> */}
            <Button variant='contained' onClick={() => {
              getGridData();
              setActiveAccordian('');
              setFilterExpanded(false);
              setFilterModelCopy(filterModel);
            }}>
              <Typography sx={{color: '#fff'}}>Apply Filters</Typography>
            </Button>
          </Box>
        </DialogContent>
      </Dialog> : <></>}
      {sortExpanded && isMobile ? <SwipeableDrawerComp 
        anchor={'bottom'} open={sortExpanded} onOpen={() => {}} onClose={() => {
          setSortExpanded(false);
          setSortModel(sortModelCopy);
          setSortModelLocal(sortModelCopy);
        }}>
        <Box sx={{padding: 5}}>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography sx={{fontWeight: 500, color: '#212121'}}>Sort:</Typography>
            <Typography sx={{color: '#212121', cursor: 'pointer'}} onClick={() => {
              setSortModel([{field: 'reportedDate', sort: 'desc'}]);
            }}>Clear</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2}}>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} ref={accRef}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Field</Typography>
                </Box>
              </Box>
              <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={{name: sortModelLocal[0].field, label: sortModelLocal[0].label}}
                  onChange={(ev, val) => {
                    console.log(val);
                    setSortModelLocal([{field: val?.name ?? 'reportedDate', sort: 'asc',  label: val?.label, sortLabel: 'Ascending'}]);
                  }}
                  options={[{name: 'unit', label: 'Unit'},
                    {name: 'category', label: 'Type'},
                    {name: 'priority', label: 'Priority'},
                    {name: 'status', label: 'Status'},
                    {name: 'dateReported', label: 'Date Received'},
                    {name: 'dateScheduled', label: 'Date Scheduled'},
                    {name: 'dateCompleted', label: 'Date Completed'}
                  ]}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.label}/>
              </Box>
            </Box>
            <Box sx={{boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)', padding: 2}} ref={accRef}>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                  <Typography>Order</Typography>
                </Box>
              </Box>
              <Box sx={{marginTop: 2}}>
                <Autocomplete size={'small'}
                  PopperComponent={(props) => {
                    return <Popper {...props}/>
                  }}
                  value={{name: sortModelLocal[0].sort, label: sortModelLocal[0].sortLabel}}
                  onChange={(ev, val) => {
                    console.log(val);
                    setSortModelLocal([{field: sortModelLocal[0].field, sort: val?.name,  label: sortModelLocal[0].label, sortLabel: val?.label }]);
                  }}
                  options={[
                    {name: 'desc', label: 'Descending'},
                    {name: 'asc', label: 'Ascending'},
                  ]}
                  renderInput={(params) => <TextField {...params} />}
                  getOptionLabel={(option: any) => option.label}/>
              </Box>
            </Box>
            <Button variant='contained' onClick={() => {
              setSortExpanded(false);
              setSortModel(sortModelLocal)
              setSortModelCopy(sortModelLocal);
            }}>
              <Typography sx={{color: '#fff'}}>Apply</Typography>
            </Button>
          </Box>
        </Box>
      </SwipeableDrawerComp> : <></>}
    </>
  );
}
