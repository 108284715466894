import { Box, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { makeReadyTasks, sortMRRows } from "@utils/helpers"
import { DateTime } from "luxon"
import AddIcon from '@mui/icons-material/AddRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Fragment, useState } from "react";
import BasicDatePicker from "@ui/dateInput";
import { Dialog } from "@ui/dialog";

export const MakeReadyBoardView = (props: any) => {

    const [calendarOpen, setCalendarOpen] = useState(false);
    const [unit, setUnit] = useState(null as any);
    const [workOrder, setWorkOrder] = useState(null as any);
    
    const getTask = (unit: any, title: any) => {
        return props.workOrders.filter((wo: any) => wo.unit === unit).find((wo: any) => wo.description === makeReadyTasks.find((mrt) => mrt.name === title)?.description);
    }

    return (
        <>
            <Typography variant="h5" sx={{mb: 2}}>Vacant Units</Typography>
            <TableContainer component={Paper} sx={{borderRadius: '16px', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)'}}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{minWidth: '100px'}}>Unit</TableCell>
                            <TableCell align="center" sx={{minWidth: '100px'}}>Move In</TableCell>
                            <TableCell align="center" sx={{minWidth: '100px'}}>Available</TableCell>
                            {makeReadyTasks.map((mrt) => <TableCell align="center" sx={{minWidth: '100px'}}>{mrt.name}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.units.filter((u: any) => ['Vacant', 'Vacant Pre-Leased'].indexOf(u.vacancyStatus) >= 0).map((u: any) => <TableRow>
                            <TableCell sx={{py:1}}>{u.unitNumber}</TableCell>
                            <TableCell align="center" sx={{py:1}}>{u.moveIn ? DateTime.fromISO(u.moveIn).toFormat('MM/dd') : '-'}</TableCell>
                            <TableCell align="center" sx={{py:1}}>{u.dateAvailable ? DateTime.fromISO(u.dateAvailable).toFormat('MM/dd') : '-'}</TableCell>
                            {makeReadyTasks.map((mrt, index) => 
                                <Fragment key={index}>
                                    {(() => {
                                        const workOrder = getTask(u.unitNumber, mrt.name);
                                        return <TableCell align="center" sx={{cursor: 'pointer'}}>
                                            {workOrder ? <Box sx={{display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center'}}>
                                                {props.isPmRole() && <CalendarTodayRoundedIcon sx={{fontSize: '16px'}} onClick={() => {
                                                    setWorkOrder(workOrder);
                                                    setUnit(u);
                                                    setCalendarOpen(true)}
                                                }/>}
                                                {workOrder.scheduledDate ? <Box onClick={() => props.onSetSelectedWo(workOrder)}>
                                                    <Typography sx={{
                                                        color: workOrder.status === 'Completed' ? 'green' : workOrder.scheduledDate && DateTime.fromISO(workOrder.scheduledDate).diffNow('days').days < 0 ? 'red' : '', 
                                                        fontWeight: workOrder.status === 'Completed' || workOrder.scheduledDate && DateTime.fromISO(workOrder.scheduledDate).diffNow('days').days < 0 ? 700 : 400,
                                                    }}>{workOrder.status === 'Completed' ? DateTime.fromISO(workOrder.completedDate).toFormat('MM/dd') : DateTime.fromISO(workOrder.scheduledDate).toFormat('MM/dd')}</Typography>
                                                </Box> : <>
                                                </>}
                                            </Box> : <Box sx={{display: 'flex', gap: 0.5, alignItems: 'center', justifyContent: 'center'}} onClick={() => props.createTasks([mrt.name], u)}>
                                                <AddIcon sx={{fontSize: '16px'}}/><Typography sx={{fontSize: '14px'}}>Add</Typography>
                                            </Box>}
                                        </TableCell>
                                    })()}
                                </Fragment>
                            )}
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h5" sx={{my: 2}}>Future</Typography>
            <TableContainer component={Paper} sx={{borderRadius: '16px', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)'}}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Unit</TableCell>
                            <TableCell align="center">Move Out/In</TableCell>
                            <TableCell align="center">Available</TableCell>
                            {makeReadyTasks.map((mrt) => <TableCell align="center">{mrt.name}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.units.filter((u: any) => ['Vacant', 'Vacant Pre-Leased'].indexOf(u.vacancyStatus) < 0).map((u: any) => <TableRow>
                            <TableCell sx={{py:1}}>{u.unitNumber}</TableCell>
                            <TableCell align="center" sx={{py:1}}>{u.moveOut ? DateTime.fromISO(u.moveOut).toFormat('MM/dd') : '-'}<br/>{u.moveIn ? DateTime.fromISO(u.moveIn).toFormat('MM/dd') : '-'}</TableCell>
                            <TableCell align="center" sx={{py:1}}>{u.dateAvailable ? DateTime.fromISO(u.dateAvailable).toFormat('MM/dd') : '-'}</TableCell>
                            {makeReadyTasks.map((mrt, index) => 
                                <Fragment key={index}>
                                    {(() => {
                                        const workOrder = getTask(u.unitNumber, mrt.name);
                                        return <TableCell align="center" sx={{cursor: 'pointer'}}>
                                            {workOrder ? <Box sx={{display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center'}}>
                                                {props.isPmRole() && <CalendarTodayRoundedIcon sx={{fontSize: '16px'}} onClick={() => {
                                                    setWorkOrder(workOrder);
                                                    setUnit(u);
                                                    setCalendarOpen(true)}
                                                }/>}
                                                {workOrder.scheduledDate ? <Box onClick={() => props.onSetSelectedWo(workOrder)}>
                                                    <Typography sx={{
                                                        color: workOrder.status === 'Completed' ? 'green' : workOrder.scheduledDate && DateTime.fromISO(workOrder.scheduledDate).diffNow('days').days < 0 ? 'red' : '', 
                                                        fontWeight: workOrder.status === 'Completed' || workOrder.scheduledDate && DateTime.fromISO(workOrder.scheduledDate).diffNow('days').days < 0 ? 700 : 400,
                                                    }}>{workOrder.status === 'Completed' ? DateTime.fromISO(workOrder.completedDate).toFormat('MM/dd') : DateTime.fromISO(workOrder.scheduledDate).toFormat('MM/dd')}</Typography>
                                                </Box> : <>
                                                </>}
                                            </Box> : <Box sx={{display: 'flex', gap: 0.5, alignItems: 'center', justifyContent: 'center'}} onClick={() => props.createTasks([mrt.name], u)}>
                                                <AddIcon sx={{fontSize: '16px'}}/><Typography sx={{fontSize: '14px'}}>Add</Typography>
                                            </Box>}
                                        </TableCell>
                                    })()}
                                </Fragment>
                            )}
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog size="md" open={calendarOpen} onClose={() => {
                setWorkOrder(null);
                setUnit(null);
                setCalendarOpen(false)
            }}>
                <DialogContent >
                    <Typography>Select Date:</Typography>
                    <BasicDatePicker open={true} onChange={(dt: any) => {
                        props.updateWorkOrder(workOrder.id, {status: 'Scheduled', scheduledDate: DateTime.fromISO(dt).toFormat('yyyy-MM-dd')}, unit);
                        setWorkOrder(null);
                        setUnit(null);
                        setCalendarOpen(false);
                    }} value={workOrder?.scheduledDate ? DateTime.fromISO(workOrder.scheduledDate).toLocal() : null}  size='small'/>
                </DialogContent>
            </Dialog>
        </>
    )
}